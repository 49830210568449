import React, { useEffect, useState } from "react";
import {BACKEND_URL} from ".././config";

const DeviceModal = ({ active, handleModel, token, id, setErrorMessage }) => {
    const [deviceName, setDeviceName] = useState("");
    const [targetDeviceName, setTargetDeviceName] = useState("");
    const [hospitalName, setHospitalName] = useState("");
    const [deviceLink, setDeviceLink] = useState("");

    useEffect(() => {
        const getDevice = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await fetch(`${BACKEND_URL}/api/devices/${id}`, requestOptions);

            if (!response.ok) {
                setErrorMessage("Could not get the device");
            } else {
                const data = await response.json();
                setDeviceName(data.device_name);
                setTargetDeviceName(data.target_device_name);
                setHospitalName(data.hospital_name);
                setDeviceLink(data.device_link);
            }
        };

        if (id) {
            getDevice();
        }
    }, [id, token]);

    const cleanFormData = () => {
        setDeviceName("");
        setTargetDeviceName("");
        setHospitalName("");
        setDeviceLink("");
    };

    const handleCreateDevice = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                device_name: deviceName,
                target_device_name: targetDeviceName,
                hospital_name: hospitalName,
                device_link: deviceLink
            }),
        };
        const response = await fetch(`${BACKEND_URL}/api/devices`, requestOptions);
        if (!response.ok) {
            setErrorMessage("Something went wrong when creating device");
        } else {
            cleanFormData();
            handleModel();
        }
    };

    const handleUpdateDevice = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                device_name: deviceName,
                target_device_name: targetDeviceName,
                hospital_name: hospitalName,
                device_link: deviceLink
            }),
        };
        const response = await fetch(`${BACKEND_URL}/api/devices/${id}`, requestOptions);
        if (!response.ok) {
            setErrorMessage("Something went wrong when updating device");
        } else {
            cleanFormData();
            handleModel();
        }
    };

    return (
        <div className={`modal ${active && "is-active"}`}>
            <div className="modal-background" onClick={handleModel}></div>
            <div className="modal-card">
                <header className="modal-card-head has-background-primary-light">
                    <h1 className="modal-card-title">
                        {id ? "Update Device" : "Add PhyLinq Device"}
                    </h1>
                </header>
                <section className="modal-card-body">
                    <form>
                        <div className="field">
                            <label className="label">Hospital</label>
                            <div className="control">
                                <input
                                    type="text"
                                    placeholder="Enter Hospital Name"
                                    value={hospitalName}
                                    onChange={(e) => setHospitalName(e.target.value)}
                                    className="input"
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Device Name</label>
                            <div className="control">
                                <input
                                    type="text"
                                    placeholder="Enter Device Name"
                                    value={deviceName}
                                    onChange={(e) =>
                                        setDeviceName(e.target.value)
                                    }
                                    className="input"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Target Device Name</label>
                            <div className="control">
                                <input
                                    type="text"
                                    placeholder="Enter Target Device Name"
                                    value={targetDeviceName}
                                    onChange={(e) =>
                                        setTargetDeviceName(e.target.value)
                                    }
                                    className="input"
                                    required
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Device Link</label>
                            <div className="control">
                                <input
                                    type="url"
                                    placeholder="Enter Device Link"
                                    value={deviceLink}
                                    onChange={(e) => setDeviceLink(e.target.value)}
                                    className="input"
                                />
                            </div>
                        </div>
                    </form>
                </section>
                <footer className="modal-card-foot has-background-primary-light">
                    {id ? (
                        <button
                            className="button is-info"
                            onClick={handleUpdateDevice}
                        >
                            Update
                        </button>
                    ) : (
                        <button
                            className="button is-primary"
                            onClick={handleCreateDevice}
                        >
                            Create
                        </button>
                    )}
                    <button className="button" onClick={handleModel}>
                        Cancel
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default DeviceModal;
