import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import ErrorMessage from "./ErrorMessage";
import DeviceModel from "./DeviceModel";
import { UserContext } from "../context/UserContext";

import {BACKEND_URL} from ".././config";

const Table = () => {
    const [token] = useContext(UserContext);
    const [devices, setDevices] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [deviceModel, setdeviceModel] = useState(false);
    const [id, setId] = useState(null);

    const handleUpdate = async (id) => {
        setId(id);
        setdeviceModel(true);
    };

    const handleDelete = async (id) => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch(`${BACKEND_URL}/api/devices/${id}`, requestOptions);
        if (!response.ok) {
            setErrorMessage("Failed to delete device");
        }

        getDevices();
    };

    const getDevices = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        };
        const response = await fetch(`${BACKEND_URL}/api/devices`, requestOptions);
        if (!response.ok) {
            setErrorMessage("Something went wrong. Couldn't load the devices");
        } else {
            const data = await response.json();
            setDevices(data);
            setLoaded(true);
        }
    };

    useEffect(() => {
        getDevices();
    }, []);

    const handleModel = () => {
        setdeviceModel(!deviceModel);
        getDevices();
        setId(null);
    };

    return (
        <>
            <DeviceModel
                active={deviceModel}
                handleModel={handleModel}
                token={token}
                id={id}
                setErrorMessage={setErrorMessage}
            />
            <button
                className="button is-fullwidth mb-5 is-primary"
                onClick={() => setdeviceModel(true)}
            >
                Add Phylinq Device
            </button>
            <ErrorMessage message={errorMessage} />
            {loaded && devices ? (
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Hospital</th>
                            <th>Device Name</th>
                            <th>Target Device Name</th>
                            <th>Device Link</th>
                            <th>Last Updated</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map((device) => (
                            <tr key={device.id}>
                                <td>{device.hospital_name}</td>
                                <td>{device.device_name}</td>
                                <td>{device.target_device_name}</td>
                                <td><a href={device.device_link} target="_blank" rel="noopener noreferrer">{device.device_link}</a></td>
                                <td>
                                    {moment(device.date_last_updated).format(
                                        "MMM Do YY"
                                    )}
                                </td>
                                <td>
                                    <button
                                        className="button mr-2 is-info is-light"
                                        onClick={() => handleUpdate(device.id)}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="button mr-2 is-danger is-light"
                                        onClick={() => handleDelete(device.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Loading</p>
            )}
        </>
    );
};

export default Table;
